import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconSVG from "../../../Utils/svg";
import CustomModal from "../modal/customModal";
import AxiosClient from "../../../Helper/axiosClient";
import { useAuthUser } from "react-auth-kit";
import { Button, Grid, TextField } from "@mui/material";
import useUpdateProjectImageCaption from "../../../Hooks/useUpdateProjectImageCaption";
import useDeleteProjectImage from "../../../Hooks/useDeleteProjectImage";
import { Delete, Edit, OpenInFull, Upload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment/moment";

const Media = ({ project, hostUrl, onEdit }) => {
	const location = useLocation();
	const userData = useAuthUser()();
	const axios = AxiosClient();
	const showUpdateMilestoneBtn = ((userData?.role?.toLowerCase() === "admin") && (location.pathname.search("/spp") === 0));
	const [openUpdateCaptionModal, setOpenUpdateCaptionModal] = useState(false);
	const [openImageDetailsModal, setOpenImageDetailsModal] = useState(false);
	const [openUploadMediaModal, setOpenUploadMediaModal] = useState(false);
	const [hasUploaded, setHasUploaded] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const { updateProjectImageCaption, dataFromCaptionUpdate, loadingCaptionUpdate } = useUpdateProjectImageCaption();
	const { dataFromDeletedImg, deleteProjectImg } = useDeleteProjectImage();
	const initialInput = {
		index: { name: "index", focus: () => { }, value: '' },
		filename: { name: "filename", focus: () => { }, value: '' },
		image: { name: "image", focus: () => { }, value: '' },
		caption: { name: "caption", focus: () => { }, value: '' },
		createdAt: { name: "createdAt", focus: () => { }, value: '' }
	}

	const [inputDetails, setInputDetails] = useState(initialInput);

	// function
	const handelInputChange = (e, dateName) => {
		let data = e.target;

		if (!data) data = { name: dateName, value: e, focus: null };

		let { name, value, focus } = data;

		let innerHtml = '';

		if (e?.target?.TagName === "SELECT") innerHtml = e?.target?.selectedOptions[0]?.innerHTML;

		setInputDetails(prev => {
			return {
				...prev,
				[name]: { name, focus, value, innerHtml }
			}
		});
	}

	const handleUpdateProjectCaption = (data) => {
		updateProjectImageCaption(data._id, data);
	}

	const handleDeleteImage = () => {
		deleteProjectImg(project._id, { filename: inputDetails.filename.value });
	}

	useEffect(() => {
		setOpenUpdateCaptionModal(false);
		setOpenUpdateCaptionModal(false);
		setOpenUploadMediaModal(false);
		onEdit(pre => pre + 1);
		console.log("Rendering...");
	}, [dataFromDeletedImg, dataFromCaptionUpdate, hasUploaded]);

	// image preview
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [previews] = useState([]);

	// create a preview as a side effect, whenever selected file is changed
	useEffect(() => {
		if (!selectedFiles.length) {
			console.log("I stop you !!!");
			return;
		}

		selectedFiles.forEach((selectedFile, index, arr) => {
			const objectUrl = URL.createObjectURL(selectedFile);
			previews.push(objectUrl);
			// free memory when ever this component is unmounted
			if (index >= arr.length - 1) setSelectedFiles([]);
			return () => URL.revokeObjectURL(objectUrl);
		});

		console.log("Rendering...1");
	}, [previews, selectedFiles]);

	const onSelectFile = e => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFiles([]);
			return;
		}

		// I've kept this example simple by using the first image instead of multiple
		setSelectedFiles(Object.values(e.target.files));
	}

	const handleUploadMedia = (data) => {
		// upload
		setIsUploading(true);
		const myFormData = new FormData();
		const file = document.getElementById("file");

		Object.values(file.files).forEach((f, index) => myFormData.append("images", file.files[index]));

		// submit project
		axios.patch('/project/update/' + project._id, myFormData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		}).then(({ data }) => {
			// get project result
			window.toastr.success(data.message);
			setIsUploading(false);
			setHasUploaded(pre => !pre);
			return data.result;
		}).catch((error) => {
			console.error("Error", error);
			window.toastr.error(error?.response ? error.response.data.message : error.message);
		});
	}

	return (
		<>
			<div className="flex-grow flex flex-col h-full position-relative" data-testid="project-tab_media_content">
				<div className="projectPage_media-navbar__gDfss">
					<div className="projectPage_media-nav__Ij9ni projectPage_media-nav-active__7QEl_">
						<p>All Pictures</p>
						<div className="projectPage_active-icon__x8GtR" />
					</div>
				</div>

				{/* Add caption */}
				<CustomModal title="Edit project image" confirm={{ confirmText: "Confirm", cancelText: "Close", isLoading: loadingCaptionUpdate, handleConfirm: handleUpdateProjectCaption, parameter: { _id: project?._id, caption: inputDetails.caption.value, index: inputDetails.index.value } }} open={{ init: openUpdateCaptionModal, set: setOpenUpdateCaptionModal }}>
					<div className="flex items-center justify-between space-x-2 bg-abandoned p-4 rounded shadow text-white">
						<h4 className="projectPage_review-author-name__C_D_Y flex">
							<span className="text-white font-filson-bold cursor-pointer">Delete Image</span>
						</h4>
						<Button onClick={handleDeleteImage} variant="outlined" color="inherit" size="small"><Delete /></Button>
					</div>
					<div className="items-center space-x-2 mt-10">
						<span className="text-light font-filson-bold cursor-pointer">Add a caption</span>

						<Grid container spacing={1}>
							<Grid item xs>
								<TextField
									fullWidth type="text"
									name={inputDetails.caption.name}
									value={inputDetails.caption.value}
									onChange={handelInputChange}
									label="Caption"
								/>
							</Grid>
						</Grid>
					</div>
				</CustomModal>

				{/* View caption */}
				<CustomModal title="Image Details" open={{ init: openImageDetailsModal, set: setOpenImageDetailsModal }}>
					<div className="flex p-4 rounded shadow w-75">
						<img alt="Second Niger Bridge" src={`${hostUrl}${inputDetails.image.value}`} />
					</div>
					<div>
						<small>Image added on {moment(inputDetails.createdAt.value).format("YYYY-MM-DD") || moment(project.createdAt).format("YYYY-MM-DD")}</small>
					</div>
					<div className="items-center space-x-2 mt-10">
						<span className="text-light cursor-pointer">{inputDetails.caption.value}</span>
					</div>

				</CustomModal>

				<div className=" projectPage_gallery___fDWv">
					{
						(project.images === undefined) ?
							(
								<div className='flex h-full flex-col items-center justify-center'>
									<div className=' mx-auto'>
										<img src={IconSVG.no_photo} alt='window wipe' className='w-96' />
									</div>
									<p className="mt-5 medium text-center text-2xl">No image yet</p>
									<p className="text-sm text-center text-input-border mt-3 w-10/12 lg:w-7/12 mx-auto">It seems that no one has updated or reviewed this project with pictures. Why not upload some with your review.</p>
									<div className="bg-white cursor-pointer text-primary hover:bg-primary hover:text-white transition ease-in-out duration-300 rounded-md px-4 py-1 mt-6">Back To Overview</div>
								</div>

							) :
							(project.images).map((image, index) => {
								return (
									<div key={image.path} className="w-full md:w-[48%] lg:w-[32.333333%] m-1">
										<div className="projectPage_gallery__item__xPnf7 overflow-visible w-full">
											<div className="projectPage_image__nKqqh">
												<span className="text-white">
													<Button onClick={() => {
														setOpenImageDetailsModal(true);
														setInputDetails(pre => {
															return {
																...pre,
																caption: {
																	...pre.caption,
																	value: image.caption
																},
																image: {
																	...pre.image,
																	value: image.path
																},
																createdAt: {
																	...pre.createdAt,
																	value: image.createdAt
																}
															}
														});
													}} sx={{ position: "absolute", top: "1em", left: "1em", zIndex: 1 }} variant="outlined" color="inherit" size="small"><OpenInFull /></Button>
													{
														showUpdateMilestoneBtn &&
														<Button onClick={() => {
															setOpenUpdateCaptionModal(true);
															setInputDetails(pre => {
																return {
																	...pre,
																	index: {
																		...pre.index,
																		value: index
																	},
																	filename: {
																		...pre.filename,
																		value: image.filename
																	}
																}
															});
														}} sx={{ position: "absolute", top: "1em", right: "1em", zIndex: 1 }} variant="contained" color="primary" size="small"><Edit /></Button>
													}
													<img alt="Second Niger Bridge" src={`${hostUrl}${image.path}`} style={{ position: 'absolute', inset: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectFit: 'cover' }} sizes="100vw" />
												</span>
											</div>
										</div>
										<div class="projectPage_project-location-card__f7FjG">
											<div class="flex items-center text-xs space-x-1">
												<p class="text-dark-grey medium">{image.caption}</p>
											</div>
											<div class="flex items-center justify-between mt-4 text-2-xs">
												<p class="uppercase medium text-input-border">{image.createdAt? moment(image.createdAt).format("YYYY-MM-DD"): moment(project.createdAt).format("YYYY-MM-DD")}</p>
											</div>
										</div>
									</div>
								)
							})
					}
				</div>

				{/* Upload image */}
				<CustomModal title="Add Image" confirm={{ confirmText: "Confirm", cancelText: "Close", handleConfirm: handleUploadMedia, isLoading: isUploading }} open={{ init: openUploadMediaModal, set: setOpenUploadMediaModal }}>
					<div className="items-center space-x-2 mt-10">
						<Grid container spacing={3} justifyContent={"center"} marginBottom={12}>
							{console.log('qpreviews', previews)}
							{
								previews.map((preview, index) =>
									<Grid key={index} item sm={6}>
										<img src={preview} alt="preview before upload" className="w-full rounded-sm shadow" />
									</Grid>
								)
							}
						</Grid>
						<Grid container spacing={1} justifyContent={"center"}>
							<Grid item>
								<input
									accept="image/*, video/*"
									style={{ display: 'none' }}
									id="file"
									multiple
									type="file"
									onChange={onSelectFile}
								/>
								<label htmlFor="file">
									<button
										data-testid="login-button"
										type="button"
										onClick={(e) => e.currentTarget.parentElement.click()}
										className="w-full rounded-full bg-primary text-white p-3 px-5 text-center">
										Select Image <Upload />
									</button>
								</label>
							</Grid>
						</Grid>
					</div>
				</CustomModal>

				{/* upload images */}
				<Button onClick={() => {
					setOpenUploadMediaModal(true);

				}} className="position-absolute rounded-full" sx={{ position: "absolute", bottom: "1em", right: "1em", zIndex: 1 }} variant="contained" color="primary" size="small"><Upload /></Button>
			</div>
		</>
	)
}

export default Media;