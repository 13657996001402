import { useState } from "react";
import AxiosClient from "../Helper/axiosClient";

const useDeleteProjectImg = () => {
	const axios = AxiosClient();
	const [dataFromDeletedImg, setData] = useState(null);
	const [loadingDeletedImg, setLoading] = useState(false);

	const deleteProjectImg = async (_id, data) => {
		setLoading(true);
		axios.delete(`/project/remove-property/${_id}?property=image`, { data: data }).then(async (res) => {
			setData(res.data.result);
			setLoading(false);
			window.toastr.success(res.data.message);
		}).catch(error => {
			// handle error
			console.log(error);
			window.toastr.error(error?.response ? error.response.data.message : error.message);
			setLoading(false);
		});
	}

	return { deleteProjectImg, dataFromDeletedImg, loadingDeletedImg }
}

export default useDeleteProjectImg;