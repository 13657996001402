import { useState } from "react";
import AxiosClient from "../Helper/axiosClient";

const useUpdateProjectImageCaption = () => {
	const axios = AxiosClient();
	const [dataFromCaptionUpdate, setData] = useState(null);
	const [loadingCaptionUpdate, setLoading] = useState(false);

	const updateProjectImageCaption = async (_id, data) => {
		setLoading(true);
		axios.patch('/project/update/caption/' + _id, data).then(async (res) => {
			setData(res.data.result);
			setLoading(false);
			window.toastr.success(res.data.message);
		}).catch(error => {
			// handle error
			console.log(error);
			window.toastr.error(error?.response ? error.response.data.message : error.message);
			setLoading(false);
		});
	}

	return { updateProjectImageCaption, dataFromCaptionUpdate, loadingCaptionUpdate }
}

export default useUpdateProjectImageCaption;